import {atom, selector} from "recoil";

const tokenAtom = atom({
    key: "TOKEN_ATOM",
    default: new Promise((resolve) => {
        const token = localStorage.getItem("access-token");
        resolve(token);
    }),
});

export const tokenState = selector({
    key: "TOKEN_SELECTOR",
    get: ({get}) => get(tokenAtom),
    set: ({set}, newValue) => {
        if (!newValue) {
            localStorage.clear();
        } else {
            localStorage.setItem("access-token", newValue);
        }
        set(tokenAtom, newValue);
    },
});

const userProfile = atom({
    key: "USER_PROFILE",
    default: new Promise((resolve) => {
        const token = localStorage.getItem("user-profile");
        resolve(token);
    }),
});

export const userProfileState = selector({
    key: "USER_PROFILE_SELECTOR",
    get: ({get}) => get(userProfile),
    set: ({set}, newValue) => {
        if (!newValue) {
            localStorage.clear();
        } else {
            localStorage.setItem("user-profile", newValue);
        }
        set(userProfile, newValue);
    },
});