import {useSetRecoilState} from "recoil";
import {tokenState, userProfileState} from "./recoil";
import {useCallback, useEffect} from "react";

const Logout = () => {
    const setToken = useSetRecoilState(tokenState);
    const setUserProfile = useSetRecoilState(userProfileState);
    const onLogout = useCallback(() => {
        setToken("");
        setUserProfile("");
        window.location.href = "/login";
    }, [setToken, setUserProfile]);

    useEffect(() => {
        onLogout();
    }, []);
}

export default Logout;