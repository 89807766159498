import AppRoute from "./routes/route";

const App = () => {
  return (
      <div>
        <AppRoute />
      </div>
  );
};

export default App;
