import React, {useEffect, useState} from 'react';
import {BarChartOutlined, CodeOutlined, LineChartOutlined, LogoutOutlined, UserOutlined,} from '@ant-design/icons';
import {Breadcrumb, Layout, Menu, theme} from 'antd';
import {Outlet, useNavigate} from 'react-router-dom';
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "../layout/error-fallback";
import {useRecoilValue} from "recoil";
import {userProfileState} from "../recoil";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon) {
    return {
        key,
        icon,
        label,
    };
}

const items = [
    getItem('Xem biểu đồ', '/', <BarChartOutlined />),
    getItem('Cấu hình mã', 'code-config', <CodeOutlined />),
    getItem('Quản lý chỉ số', 'value-chart', <LineChartOutlined />),
    getItem('Quản lý user', 'user', <UserOutlined />),
    getItem('Đăng xuất', '/logout', <LogoutOutlined />),
];

const Management = () => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const navigate = useNavigate();
    const userProfile = useRecoilValue(userProfileState);

    const onMenuClick = (item) => {
        navigate(item.key);
    };

    useEffect(() => {
        if (userProfile && JSON.parse(userProfile)?.role !== 'ROLE_ADMIN') {
            navigate('/');
        }
    }, []);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="demo-logo-vertical" />
                <Menu
                    theme="dark"
                    defaultSelectedKeys={['/code-config']}
                    mode="inline"
                    items={items}
                    onClick={onMenuClick}
                />
            </Sider>
            <Layout>
                <Header style={{ padding: 0, background: colorBgContainer }} />
                <Content style={{ margin: '0 16px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>{window.location.pathname}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Outlet />
                        </ErrorBoundary>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Copyright © 2021 by LP INVESTMENT.
                </Footer>
            </Layout>
        </Layout>
    );
};

export default Management;
