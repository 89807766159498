import {Flex} from "@chakra-ui/react";
import logo from '../../assets/images/logo.png';
import fb from '../../assets/images/fb.svg';
import yt from '../../assets/images/youtube.svg';
import tt from '../../assets/images/tiktok.svg';
import location from '../../assets/images/location.svg';
import mail from '../../assets/images/mail.svg';
import phone from '../../assets/images/phone.svg';
import {useMediaQuery} from "../../utils/helper";

const Footer = () => {
    const isMobileAndTablet = useMediaQuery('(max-width: 992px)');

    return (
        <Flex
            pt={isMobileAndTablet ? 50 : 80}
            w={'100%'}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            background={'#02052F'}>
            <Flex flexDir={"column"} w={'90%'} justifyContent={"center"} alignItems={"center"}>
                <Flex w={'100%'}
                      flexDir={isMobileAndTablet && "column"}
                      justifyContent={"space-between"}
                      alignItems={"start"}
                      pb={isMobileAndTablet ? 16 : 96}
                      borderBottom={'1px solid #595959'}>
                    <Flex w={'100%'} flexDir={"column"} justifyContent={"start"} alignItems={"start"}>
                        <img src={logo} alt={logo}/>
                        {!isMobileAndTablet &&
                            <div className={'footer-title-1'}>Kết nối với chúng tôi</div>
                        }
                        {!isMobileAndTablet &&
                            <Flex>
                                <img style={{marginRight: 24}} src={fb} alt={fb}/>
                                <img style={{marginRight: 24}} src={yt} alt={yt}/>
                                <img src={tt} alt={tt}/>
                            </Flex>
                        }
                    </Flex>
                    <Flex
                        mt={isMobileAndTablet && 24}
                        flexDir={isMobileAndTablet && "column"}
                        className={'_footer_1_'}
                        w={'100%'}>
                        <Flex
                            borderBottom={isMobileAndTablet && '1px solid #595959'}
                            w={'100%'}
                            flexDir={"column"}
                            pb={isMobileAndTablet && 16}
                            justifyContent={"start"} alignItems={"start"}>
                            <Flex
                                mb={isMobileAndTablet && 16}
                                className="footer-title">Về chúng tôi
                            </Flex>
                            <p>Về chúng tôi</p>
                            <p>Magazine</p>
                            <p>Điều khoản</p>
                            <p>Chính sách bảo mật</p>
                        </Flex>
                        <Flex
                            mt={isMobileAndTablet && 16}
                            pb={isMobileAndTablet && 16}
                            w={'100%'} flexDir={"column"}
                            justifyContent={"start"} alignItems={"start"}>
                            <div className="footer-title">Thông tin liên hệ</div>
                            <Flex alignItems={"start"} mb={16}>
                                <img src={location} alt={location}/>
                                <span>Tầng 5, số 257-259 Trần Đăng Ninh, Dịch Vọng, Cầu Giấy, Hà Nội</span>
                            </Flex>
                            <Flex alignItems={"start"} mb={16}>
                                <img src={mail} alt={mail}/>
                                <span>info@tfindex.com.vn</span>
                            </Flex>
                            <Flex alignItems={"start"}>
                                <img src={phone} alt={phone}/>
                                <span>(+84) 123 456 789</span>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    mt={isMobileAndTablet && 16}
                    className={"_copy_right_"} flexDir={"column"}>
                    {isMobileAndTablet &&
                        <Flex justifyContent={"center"} alignItems={"center"}>
                            <img style={{marginRight: 24}} src={fb} alt={fb}/>
                            <img style={{marginRight: 24}} src={yt} alt={yt}/>
                            <img src={tt} alt={tt}/>
                        </Flex>
                    }
                    <div
                        className={isMobileAndTablet ? "_copy_right_mobile" : ""}
                    >
                        Copyright © 2021 by LP INVESTMENT. All rights reserved
                    </div>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Footer;