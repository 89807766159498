import {Flex} from "@chakra-ui/react";
import TableValueChart from "./table";

const ValueChart = () => {
    return (
        <Flex flexDir={"column"}>
            <TableValueChart/>
        </Flex>
    )
}

export default ValueChart;