import {Flex, Input} from "@chakra-ui/react";
import arrow from '../../../assets/images/arrow.svg';
import {useMediaQuery} from "../../../utils/helper";
import StockChart from "./stock-chart";
import {useEffect, useState} from "react";
import {Checkbox, Divider, message, Modal} from "antd";
import {serviceLp} from "../../../utils/service";

const CheckboxGroup = Checkbox.Group;

const Chart = () => {
    const isMobileAndTablet = useMediaQuery('(max-width: 992px)');
    const today = new Date();
    const defaultStartTime = new Date(2022, 2, 2);

    const minDate = new Date(2022, 2, 2);

    const maxDate = today.toISOString().slice(0, 10);

    const maxDateOneDayAgo = new Date();
    maxDateOneDayAgo.setDate(maxDateOneDayAgo.getDate() - 1);

    const [startTime, setStartTime] = useState(defaultStartTime.toISOString().slice(0, 10));
    const [endTime, setEndTime] = useState(today.toISOString().slice(0, 10));
    const [plainOptions, setPlainOptions] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        serviceLp.getAllCode().then((res) => {
            setPlainOptions(res.map((data) => (data?.code)))
            setCheckedList(res.map((data) => (data?.code)))
        }).catch((err) => {
            message.error(err?.description).then(r => console.log(r));
        });
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onChange = (list) => {
        setCheckedList(list);
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
    };

    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value);
    };

    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value);
    };

    return (
        <Flex w={isMobileAndTablet && '90%'} className={'_chart_'}>
            <Flex width={'80%'} h={'10%'} flexDir={isMobileAndTablet && "column"} justifyContent={"space-between"}
                  alignItems={"center"}>
                <Flex w={"fit-content"} fontSize={isMobileAndTablet && 20} className={'text-box'}
                      justifyContent={"center"} alignItems={"center"} onClick={showModal} cursor={"pointer"}>
                    Performance
                </Flex>
                <Flex w={"fit-content"} fontSize={isMobileAndTablet && 20} mt={isMobileAndTablet && 20}
                      className={'text-box'} justifyContent={"center"} alignItems={"center"}>
                    <Modal open={isModalOpen} footer={null} onCancel={handleOk}>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                            Check all
                        </Checkbox>
                        <Divider/>
                        <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange}/>
                    </Modal>
                </Flex>
                <Flex w={!isMobileAndTablet && '50%'} mt={isMobileAndTablet && 20} justifyContent={"center"}
                      alignItems={"center"}>
                    <Input className={!isMobileAndTablet ? '_date_chart_' : '_date_chart_mobile'}
                           onChange={handleStartTimeChange}
                           value={startTime}
                           min={minDate.toISOString().slice(0, 10)}
                           max={maxDateOneDayAgo.toISOString().slice(0, 10)}
                           type={"date"}/>
                    <img src={arrow} alt={arrow}/>
                    <Input className={!isMobileAndTablet ? '_date_chart_' : '_date_chart_mobile'}
                           onChange={handleEndTimeChange}
                           value={endTime}
                           min={startTime}
                           max={today.toISOString().slice(0, 10)}
                           type={"date"}/>
                </Flex>
            </Flex>
            <Flex width={'80%'}
                  h={'400px'}
                  mt={isMobileAndTablet ? 96 : 48}
                  justifyContent={"space-between"}
                  alignItems={"center"}>
                <StockChart startTime={startTime} endTime={endTime} symbols={checkedList}
                            minDate={minDate.toISOString().slice(0, 10)} maxDate={maxDate}/>
            </Flex>
        </Flex>
    )
}

export default Chart