import bG from '../../assets/images/background.png';
import logo from '../../assets/images/logo.png';
import {Flex} from "@chakra-ui/react";
import Chart from "./chart/chart";
import Calculation from "./calculation";
import Footer from "../layout/footer";
import {useCallback, useEffect, useState} from "react";
import {useMediaQuery} from "../../utils/helper";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {tokenState, userProfileState} from "../recoil";
import {useNavigate} from "react-router-dom";
import {Dropdown, Space} from "antd";
import {FaRegUserCircle} from "react-icons/fa";
import {BarChartOutlined, DownOutlined, LogoutOutlined} from "@ant-design/icons";

const Home = () => {
    const [choose, setChoose] = useState(1);
    const [userProfile, setUserProfile] = useRecoilState(userProfileState);
    const token = useRecoilValue(tokenState);
    const navigate = useNavigate();
    const isMobileAndTablet = useMediaQuery('(max-width: 992px)');
    const [items, setItems] = useState([]);
    const setToken = useSetRecoilState(tokenState);
    const onLogout = useCallback(() => {
        setToken("");
        setUserProfile("");
        window.location.href = "/login";
    }, [setToken, setUserProfile]);

    useEffect(() => {
        if (!token) {
            navigate("login");
        }

        if (userProfile && JSON.parse(userProfile)?.role === 'ROLE_ADMIN') {
            setItems([
                {
                    key: '1',
                    label: (
                        <a href="/management">
                            Quản lý
                        </a>
                    ),
                    icon: <BarChartOutlined/>,
                },
                {
                    key: '2',
                    label: (
                        <a onClick={() => {
                            onLogout();
                        }}>
                            Đăng xuất
                        </a>
                    ),
                    icon: <LogoutOutlined/>,
                },
            ])
        } else {
            setItems([
                {
                    key: '2',
                    label: (
                        <a onClick={() => {
                            onLogout();
                        }}>
                            Đăng xuất
                        </a>
                    ),
                    icon: <LogoutOutlined/>,
                },
            ])
        }
    }, [userProfile]);
    return (
        <>
            <Flex h={'1000px'} w={'100%'}
                  backgroundColor={'#F4F5FE'}
                  position={"absolute"}>
            </Flex>
            <img className={'bg1'} style={isMobileAndTablet ? {height: 500} : {}} src={bG} alt={bG}/>
            <Flex w={'100%'} position={"absolute"} zIndex={2} flexDir={"column"}>
                <Flex justifyContent={"center"}
                      alignItems={"center"}>
                    <Flex w={'90%'}
                          p={'1.5rem 0'}
                          justifyContent={"center"}
                          borderBottom={'0.5px solid #E0E0E0'}
                          alignItems={"center"}>
                        <Flex w={'20%'}
                              justifyContent={"center"}
                              alignItems={"center"}>

                        </Flex>
                        <Flex w={'60%'}
                              justifyContent={"center"}
                              alignItems={"center"}>
                            <img src={logo} alt={logo}/>
                        </Flex>
                        <Flex w={'20%'}
                              justifyContent={"center"}
                              alignItems={"center"}>
                            <Flex w={"fit-content"}
                                  borderRadius={5}
                                  p={8}
                                  cursor={"pointer"}
                                  backgroundColor={'#FFF'}
                                  justifyContent={"center"}
                                  alignItems={"center"}>
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="bottom"
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <Flex justifyContent={"center"}
                                                  alignItems={"center"}>
                                                <Flex fontSize={14} fontWeight={600} justifyContent={"center"}
                                                      alignItems={"center"}>
                                                    {userProfile && JSON.parse(userProfile)?.username}
                                                </Flex>
                                                <FaRegUserCircle style={{marginLeft: 5, marginRight: 10}}
                                                                 fontSize={20}/>
                                                <DownOutlined/>
                                            </Flex>
                                        </Space>
                                    </a>
                                </Dropdown>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                {/*<Flex w={'100%'} flexDir={"column"} justifyContent={"center"} alignItems={"center"}>*/}
                {/*    <Flex w={isMobileAndTablet && '85%'} className={'choose-1'} display={"none"}>*/}
                {/*        <Flex onClick={() => setChoose(1)}*/}
                {/*              fontSize={isMobileAndTablet && 16}*/}
                {/*              className={choose === 1 ? 'is_choose choose-1-2-3' : 'choose-1-2-3'}>*/}
                {/*            Quant*/}
                {/*        </Flex>*/}
                {/*        <Flex borderRight={isMobileAndTablet && "none"} onClick={() => setChoose(2)}*/}
                {/*              fontSize={isMobileAndTablet && 16}*/}
                {/*              className={choose === 2 ? 'is_choose choose-1-2-3 choose-1-2' : 'choose-1-2-3 choose-1-2'}>*/}
                {/*            Fundamental*/}
                {/*        </Flex>*/}
                {/*        {!isMobileAndTablet &&*/}
                {/*            <Flex onClick={() => setChoose(3)}*/}
                {/*                  className={choose === 3 ? 'is_choose choose-1-2-3' : 'choose-1-2-3'}>*/}
                {/*                Quant + Fundamental*/}
                {/*            </Flex>*/}
                {/*        }*/}
                {/*    </Flex>*/}
                {/*    {isMobileAndTablet &&*/}
                {/*        <Flex w={isMobileAndTablet && '85%'} className={'choose-1'} mt={8}>*/}
                {/*            <Flex onClick={() => setChoose(3)}*/}
                {/*                  fontSize={16}*/}
                {/*                  className={choose === 3 ? 'is_choose choose-1-2-3' : 'choose-1-2-3'}>*/}
                {/*                Quant + Fundamental*/}
                {/*            </Flex>*/}
                {/*        </Flex>*/}
                {/*    }*/}
                {/*</Flex>*/}

                <Flex mt={40} justifyContent={"center"} alignItems={"center"}>
                    <Chart/>
                </Flex>

                <Flex mt={isMobileAndTablet ? '1rem' : '2rem'} pb={isMobileAndTablet ? 48 : 100}
                      backgroundColor={'#F4F5FE'}
                      justifyContent={"center"} alignItems={"center"}>
                    <Calculation/>
                </Flex>

                <Footer/>
            </Flex>
        </>
    )
}

export default Home