import {Button, Form, Input, message, Modal} from "antd";
import PropTypes from "prop-types";
import {Flex} from "@chakra-ui/react";
import {serviceLp} from "../../../utils/service";
import {CREATE, DELETE, EDIT, VIEW} from "../../../utils/const";
import {useEffect} from "react";

const ModalCodeConfig = (props) => {
    const {isModalOpen, setIsModalOpen, type, record, fetchData} = props;
    const [form] = Form.useForm();

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    useEffect(() => {
        switch (type) {
            case CREATE:
                form.resetFields();
                break;
            case VIEW:
            case EDIT:
            case DELETE:
                form.setFieldValue("username", record?.username);
                form.setFieldValue("newPassword", record?.newPassword);
                form.setFieldValue("confirmNewPassword", record?.confirmNewPassword);
                break;
            default:
                break;
        }
    }, [isModalOpen]);

    const onFinish = (value) => {
        let params
        try {
            params = {
                username: value?.username,
                newPassword: value?.newPassword,
                confirmNewPassword: value?.confirmNewPassword
            };
        } catch (err) {
            params = {
                username: value?.username,
                newPassword: value?.newPassword,
                confirmNewPassword: record?.confirmNewPassword
            };
        }

        switch (type) {
            case CREATE:
                serviceLp.createCode(params).then(() => {
                    message.success("Thêm mới thành công!");
                    fetchData();
                }).catch(e => {
                    message.error(e?.description);
                    console.log(e)
                }).then(handleCancel)
                break;
            case EDIT:
                serviceLp.changePassword(params).then(() => {
                    message.success("Đổi mật khẩu thành công!");
                    fetchData();
                }).catch(e => {
                    message.error(e?.description);
                    console.log(e)
                }).then(handleCancel)
                break;
            case DELETE:
                serviceLp.deleteCode(record?.code).then(() => {
                    message.success("Xóa thành công code " + record?.code + " !");
                    fetchData();
                }).catch(e => {
                    message.error(e?.description);
                    console.log(e)
                }).then(handleCancel)
                break;
            default:
        }
    };

    return (
        <Modal open={isModalOpen}
               onCancel={handleCancel}
               destroyOnClose
               footer={null}>
            <Form form={form} onFinish={onFinish}>
                <Flex mt={30}></Flex>
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'username is required!',
                        },
                    ]}
                >
                    <Input disabled={type === VIEW || type === DELETE || type === EDIT}/>
                </Form.Item>
                <Form.Item
                    label="New Password"
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: 'newPassword is required!',
                        },
                    ]}
                >
                    <Input disabled={type === VIEW || type === DELETE} autoSize/>
                </Form.Item>
                <Form.Item
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    rules={[
                        {
                            required: true,
                            message: 'confirmNewPassword is required!',
                        },
                    ]}
                >
                    <Input disabled={type === VIEW || type === DELETE} showText/>
                </Form.Item>
                {type === DELETE ?
                    <Button type="primary" htmlType="submit" onClick={onFinish}>
                        Delete
                    </Button> :
                    <Button disabled={type === VIEW} type="primary" htmlType="submit">
                        {type === CREATE && 'Create'}
                        {type === EDIT && 'Edit'}
                    </Button>
                }
            </Form>
        </Modal>
    )
}

ModalCodeConfig.propTypes = {
    isModalOpen: PropTypes.bool, // Define prop types here
    setIsModalOpen: PropTypes.func,
    type: PropTypes.string,
    record: PropTypes.object,
};

export default ModalCodeConfig;