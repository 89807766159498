import {Button, Flex, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import chartIcon from "../../assets/images/chart-icon.png";
import {useState} from "react";
import {useMediaQuery} from "../../utils/helper";
import {Form, message} from "antd";
import {serviceLp} from "../../utils/service";

const Calculation = () => {
    const [typeStartDate, setTypeStartDate] = useState("text")
    const [typeEndDate, setTypeEndDate] = useState("text")
    const isMobileAndTablet = useMediaQuery('(max-width: 992px)');
    const [endingNav, setEndingNav] = useState('');
    const [investedCapital, setInvestedCapital] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const onCalculate = () => {
        if (!endingNav || !investedCapital || !startDate || !endDate) {
            message.error("Please fill in all fields");
            return;
        }
        serviceLp.calculatePerformance({
            endingNAV: endingNav,
            investedCapital: investedCapital,
            startDate: startDate,
            endDate: endDate
        }).then(res => {
            alert("totalReturn: " + res?.totalReturn + "\n" + "annualizedReturn: " + res?.annualizedReturn)
        }).catch(err => {
            console.log(err);
            message.error(err?.description).then(r => console.log(r));
        })
    }

    return (
        <Flex w={isMobileAndTablet && '90%'} className={'_chart_'}>
            <Flex width={'80%'} justifyContent={"space-between"} alignItems={"center"}
                  flexDir={isMobileAndTablet && "column"}>
                {isMobileAndTablet &&
                    <Flex justifyContent={"center"} alignItems={"center"} w={'80%'}>
                        <img src={chartIcon} alt={chartIcon}/>
                    </Flex>
                }
                <Flex flexDir={"column"} w={isMobileAndTablet ? '100%' : '60%'}>
                    <Flex className={'text-box'} fontSize={isMobileAndTablet && 20} mt={isMobileAndTablet && 16}
                          justifyContent={isMobileAndTablet ? "center" : "flex-start"} alignItems={"center"}>
                        Performance Calculation
                    </Flex>
                    <Flex style={{
                        textAlign: "start",
                        marginTop: 14,
                        marginBottom: 24
                    }}>
                        See how your savings and investment account balances can grow with the magic of compound
                        interest.
                    </Flex>
                    <Form>

                    </Form>
                    <Flex flexDir={"column"}>
                        <div className={'_title_1_calculation'}>End of period Net Asset Value</div>
                        <InputGroup mt={10} className="input-amount" w={"100%"}>
                            <Input w={"100%"} p={'1rem 4rem 1rem 1rem'}
                                   borderRadius={5}
                                   background={'#FFF'}
                                   border={'1px solid var(--Gray-5, #E0E0E0)'}
                                   placeholder='Enter endingNav'
                                   onChange={(e) => {
                                       setEndingNav(e.target.value);
                                   }}
                                   value={endingNav}
                            />
                            <InputRightElement h={'100%'}>
                                <Flex p={"0 10px 0 0"} height={'100%'} justifyContent={"center"}
                                      alignItems={"center"}>VND</Flex>
                            </InputRightElement>
                        </InputGroup>
                    </Flex>
                    <Flex mt={16} flexDir={"column"}>
                        <div className={'_title_1_calculation'}>Investment Amount</div>
                        <InputGroup mt={10} className="input-amount" w={"100%"}>
                            <Input w={"100%"} p={'1rem 4rem 1rem 1rem'}
                                   borderRadius={5}
                                   background={'#FFF'}
                                   border={'1px solid var(--Gray-5, #E0E0E0)'}
                                   placeholder='Enter amount'
                                   onChange={(e) => {
                                       setInvestedCapital(e.target.value);
                                   }}
                                   value={investedCapital}
                            />
                            <InputRightElement h={'100%'}>
                                <Flex p={"0 10px 0 0"} height={'100%'} justifyContent={"center"}
                                      alignItems={"center"}>VND</Flex>
                            </InputRightElement>
                        </InputGroup>
                    </Flex>
                    <Flex mt={16} flexDir={"column"}>
                        <div className={'_title_1_calculation'}>Investment Period</div>
                        <Flex mt={10} justifyContent={"space-between"} flexDir={isMobileAndTablet && "column"}
                              alignItems={"center"}>
                            <Input mr={!isMobileAndTablet && 16}
                                   width={isMobileAndTablet ? '90%' : '100%'}
                                   className={'_date_chart_'}
                                   type={typeStartDate}
                                   onFocus={() => {
                                       setTypeStartDate("date")
                                   }}
                                   onBlur={() => {
                                       setTypeStartDate("text")
                                   }}
                                   placeholder={"Start date"}
                                   onChange={(e) => {
                                       setStartDate(e.target.value);
                                   }}
                                   value={startDate}
                            />
                            <Input width={isMobileAndTablet ? '90%' : '100%'}
                                   mt={isMobileAndTablet && 8}
                                   className={'_date_chart_'}
                                   type={typeEndDate}
                                   onFocus={() => {
                                       setTypeEndDate("date")
                                   }}
                                   onBlur={() => {
                                       setTypeEndDate("text")
                                   }}
                                   placeholder={"End date"}
                                   onChange={(e) => {
                                       setEndDate(e.target.value);
                                   }}
                                   value={endDate}
                            />
                        </Flex>
                    </Flex>
                    <Button width={isMobileAndTablet ? "100%" : "48.5%"}
                            display={"flex"}
                            justifyContent={"center"}
                            p={'16px 80px'}
                            borderRadius={5}
                            background={'#2939F5'}
                            mt={24}
                            border={"none"}
                            color={'#FFF'}
                            fontSize={15}
                            fontWeight={600}
                            fontStyle={"normal"}
                            lineHeight={'100%'}
                            fontFamily={'-apple-system, Be Vietnam, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
                                '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
                                '    sans-serif'}
                            alignItems={"center"}
                            onClick={onCalculate}
                    >
                        Calculate
                    </Button>
                </Flex>

                {!isMobileAndTablet &&
                    <Flex justifyContent={"center"} alignItems={"center"} w={'30%'}>
                        <img src={chartIcon} alt={chartIcon}/>
                    </Flex>
                }
            </Flex>

            {/*<Flex width={'80%'}*/}
            {/*      h={'400px'}*/}
            {/*      mt={48}*/}
            {/*      justifyContent={"space-between"}*/}
            {/*      alignItems={"center"}>*/}
            {/*</Flex>*/}
        </Flex>
    )
}

export default Calculation