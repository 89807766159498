import {Button, DatePicker, Form, Input, message, Modal, Select} from "antd";
import PropTypes from "prop-types";
import {Flex} from "@chakra-ui/react";
import {serviceLp} from "../../../utils/service";
import {CREATE, DELETE, EDIT, VIEW} from "../../../utils/const";
import React, {useEffect, useState} from "react";
import moment from 'moment';
import {format} from "date-fns";

const ModalValueChart = (props) => {
    const {isModalOpen, setIsModalOpen, type, record, fetchData} = props;
    const [form] = Form.useForm();
    const [date, setDate] = useState();
    const [optionCodes, setOptionCodes] = useState([]);

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    useEffect(() => {
        serviceLp.getAllCode().then((res) => {
            setOptionCodes(res.map(data => ({
                value: data?.code,
                label: data?.code,
            })))
        }).catch((err) => {
            message.error(err?.description).then(r => console.log(r));
        });
    }, []);

    useEffect(() => {
        switch (type) {
            case CREATE:
                form.resetFields();
                break;
            case VIEW:
            case EDIT:
            case DELETE:
                form.resetFields();
                form.setFieldValue("symbol", record?.symbol);
                form.setFieldValue("close", record?.close);
                form.setFieldValue("date", record?.date ? moment(record?.date, 'YYYY-MM-DD') : null);
                break;
            default:
                break;
        }
    }, [isModalOpen]);

    const onFinish = (value) => {
        const date = value?.date ? format(new Date(value?.date), 'yyyy-MM-dd') : ''
        const params = {
            symbol: value?.symbol,
            close: value?.close,
            date: date
        };

        switch (type) {
            case CREATE:
                serviceLp.createDataChart(params).then(() => {
                    message.success("Thêm mới thành công!");
                    fetchData();
                }).catch(e => {
                    message.error(e?.description);
                    console.log(e)
                }).then(handleCancel)
                break;
            case EDIT:
                serviceLp.updateDataChart(record?.id, params).then(() => {
                    message.success("Chỉnh sửa thành công!");
                    fetchData();
                }).catch(e => {
                    message.error(e?.description);
                    console.log(e)
                }).then(handleCancel)
                break;
            case DELETE:
                serviceLp.deleteDataChart(record?.id).then(() => {
                    message.success("Xóa thành công !");
                    fetchData();
                }).catch(e => {
                    message.error(e?.description);
                    console.log(e)
                }).then(handleCancel)
                break;
            default:
        }
    };

    return (
        <Modal open={isModalOpen}
               onCancel={handleCancel}
               destroyOnClose
               footer={null}>
            <Form form={form} onFinish={onFinish}>
                <Flex mt={30}></Flex>
                <Form.Item
                    label="Symbol"
                    name="symbol"
                    rules={[
                        {
                            required: true,
                            message: 'Code is required!',
                        },
                    ]}
                >
                    {/*<Input disabled={type === VIEW || type === DELETE}/>*/}
                    <Select
                        options={optionCodes}
                    />
                </Form.Item>
                <Form.Item
                    label="Close"
                    name="close"
                    rules={[
                        {
                            required: true,
                            message: 'Close is required!',
                        },
                    ]}
                >
                    <Input disabled={type === VIEW || type === DELETE}/>
                </Form.Item>
                <Form.Item
                    label="Date"
                    name="date"
                    rules={[
                        {
                            required: true,
                            message: 'Date is required!',
                        },
                    ]}
                >
                    <DatePicker format="YYYY-MM-DD" disabled={type === VIEW || type === DELETE}/>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    Done
                </Button>
            </Form>
        </Modal>
    )
}

ModalValueChart.propTypes = {
    isModalOpen: PropTypes.bool, // Define prop types here
    setIsModalOpen: PropTypes.func,
    type: PropTypes.string,
    record: PropTypes.object,
    fetchData: PropTypes.func,
};

export default ModalValueChart;