import React, {useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import {serviceLp} from "../../../utils/service";

const StockChart = ({startTime, endTime, minDate, maxDate, symbols}) => {
    const [vnIndex, setVnIndex] = useState([]);
    const initialData = {
        series: [
            {
                name: 'VNINDEX',
                data: vnIndex?.map(item => item?.cumReturn),
            },
        ],
        options: {
            chart: {
                height: 350,
                type: 'area',
                events: {
                    beforeZoom: (e, {xaxis}) => {
                        let maindifference = (new Date(maxDate)).valueOf() - new Date(minDate).valueOf();
                        let zoomdifference = xaxis.max - xaxis.min;
                        if (zoomdifference > maindifference)
                            return {
                                xaxis: {
                                    min: minDate,
                                    max: maxDate
                                }
                            };
                        else {
                            return {
                                // keep on zooming
                                xaxis: {
                                    min: xaxis.min,
                                    max: xaxis.max
                                }
                            }
                        }
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 1,
                curve: 'smooth',
            },
            xaxis: {
                type: 'datetime',
                categories: vnIndex?.map(item => item.date),
                labels: {
                    format: 'dd/MM/yyyy'
                },
                min: startTime,
                max: endTime
            },
            tooltip: {
                shared: true,
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                }
            },
            colors: ['#2939F5'],
        },
    };

    const [chartData, setChartData] = useState(initialData);

    const extracted = (response) => {
        setVnIndex(response?.VNINDEX);

        const keys = Object.keys(response);
        const series = keys.map(key => ({
            name: key,
            data: response[key].map(item => ([
                item.date,
                item?.cumReturn
            ]))
        }));

        const uniqueDates = new Set();
        keys.forEach(key => {
            response[key].forEach(item => {
                uniqueDates.add(item.date);
            });
        });
        const uniqueDateSort = [...uniqueDates].sort();

        const colors = keys.map(key => (
            response[key][0].color
        ));
        const initialData = {
            series: series,
            options: {
                chart: {
                    height: 350,
                    type: 'area',
                    events: {
                        beforeZoom: (e, {xaxis}) => {
                            let maindifference = (new Date(endTime)).valueOf() - new Date(startTime).valueOf();
                            let zoomdifference = xaxis.max - xaxis.min;
                            if (zoomdifference > maindifference)
                                return {
                                    xaxis: {
                                        min: minDate,
                                        max: maxDate
                                    }
                                };
                            else {
                                return {
                                    // keep on zooming
                                    xaxis: {
                                        min: xaxis.min,
                                        max: xaxis.max
                                    }
                                }
                            }
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 1,
                    curve: 'smooth',
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(2) + "%";
                        }
                    }
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 8,
                    categories: uniqueDateSort.map(item => item.date),
                    labels: {
                        format: 'dd/MM/yyyy'
                    },
                    min: startTime,
                    max: endTime
                },
                tooltip: {
                    shared: true
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                    }
                },
                colors: colors,
            },
        };
        setChartData(initialData)
    }

    useEffect(() => {
        if (!symbols || symbols.length === 0) {
            extracted({});
            return;
        }
        serviceLp.getDataChart({
            fromDate: startTime,
            toDate: endTime,
            symbols: symbols.join(',')
        }).then(response => {
            extracted(response);
        }).catch(e => {
            console.log(e)
        });
    }, [startTime, endTime, symbols]);

    return (
        <div id="chart">
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="area"
                height={350}
            />
        </div>
    );
}

export default StockChart;
