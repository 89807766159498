import React, {useEffect, useState} from 'react';
import {message, Space, Table} from 'antd';
import {serviceLp} from "../../../utils/service";
import {Flex} from "@chakra-ui/react";
import ModalCodeConfig from "./modal";
import {EDIT} from "../../../utils/const";

const TableUser = () => {
    const [data, setData] = useState([]);
    const [type, setType] = useState('');
    const [record, setRecord] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        serviceLp.getAllUser().then((res) => {
            setData(res.map((data, index) => ({
                key: index,
                username: data?.username,
                role: data?.role,
                createdDate: data?.createdDate,
                updatedDate: data?.updatedDate,
            })))
        }).catch((err) => {
            message.error(err?.description).then(r => console.log(r));
        });
    }

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
        },
        {
            title: 'Updated Date',
            dataIndex: 'updatedDate',
            key: 'updatedDate',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                    <Space size="middle">
                        <a onClick={() => {
                            setRecord(record);
                            showModal(EDIT);
                        }}>Đổi mật khẩu</a>
                    </Space>
            ),
        },
    ];

    const showModal = (type) => {
        setIsModalOpen(true);
        setType(type);
    };

    return (
        <Flex flexDir={"column"}>
            <ModalCodeConfig isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} type={type} record={record}
                             fetchData={fetchData}/>
            {/*<Flex justifyContent={"end"}>*/}
            {/*    <Button*/}
            {/*        type="primary"*/}
            {/*        onClick={() => showModal(CREATE)}*/}
            {/*        style={{marginBottom: 20, width: "fit-content"}}*/}
            {/*    >*/}
            {/*        Thêm mới*/}
            {/*    </Button>*/}
            {/*</Flex>*/}
            <Table columns={columns} dataSource={data}/>
        </Flex>
    )
}
export default TableUser;