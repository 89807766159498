import React, {useEffect, useState} from 'react';
import {Button, ColorPicker, message, Space, Table} from 'antd';
import {serviceLp} from "../../../utils/service";
import {Flex} from "@chakra-ui/react";
import ModalCodeConfig from "./modal";
import {CREATE, DELETE, EDIT, VIEW, VNINDEX} from "../../../utils/const";

const TableCodeConfig = () => {
    const [data, setData] = useState([]);
    const [type, setType] = useState('');
    const [record, setRecord] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        serviceLp.getAllCode().then((res) => {
            setData(res.map((data, index) => ({
                id: data?.id,
                key: index,
                description: data?.description,
                code: data?.code,
                color: data?.color,
                creator: data?.creator,
                createdDate: data?.createdDate,
            })))
        }).catch((err) => {
            message.error(err?.description).then(r => console.log(r));
        });
    }

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (_, record) => (
                <a onClick={() => {
                    setRecord(record);
                    showModal(VIEW);
                }}>
                    {record?.code}
                </a>),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
            render: (color) => (
                <ColorPicker value={color} disabled showText/>
            )
        },
        {
            title: 'Creator',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                record?.code === VNINDEX ?
                    <Space size="middle">
                        <a style={{cursor: "no-drop"}}>Chỉnh sửa</a>
                        <a style={{cursor: "no-drop"}}>Xóa</a>
                    </Space>
                    :
                    <Space size="middle">
                        <a onClick={() => {
                            setRecord(record);
                            showModal(EDIT);
                        }}>Chỉnh sửa</a>
                        <a onClick={() => {
                            setRecord(record);
                            showModal(DELETE);
                        }}>Xóa</a>
                    </Space>
            ),
        },
    ];

    const showModal = (type) => {
        setIsModalOpen(true);
        setType(type);
    };

    return (
        <Flex flexDir={"column"}>
            <ModalCodeConfig isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} type={type} record={record}
                             fetchData={fetchData}/>
            <Flex justifyContent={"end"}>
                <Button
                    type="primary"
                    onClick={() => showModal(CREATE)}
                    style={{marginBottom: 20, width: "fit-content"}}
                >
                    Thêm mới
                </Button>
            </Flex>
            <Table columns={columns} dataSource={data}/>
        </Flex>
    )
}
export default TableCodeConfig;