import {useCallback, useEffect, useState} from "react";

export const useMediaQuery = (query) => {
    const getMatches = (query) => {
        if (typeof window !== 'undefined') {
            return window.matchMedia(query).matches;
        }
        return false;
    };

    const [matches, setMatches] = useState(getMatches(query));
    const handleChange = useCallback(() => setMatches(getMatches(query)), [query]);

    useEffect(() => {
        const matchMedia = window.matchMedia(query);
        handleChange();

        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange);
        } else {
            matchMedia.addEventListener('change', handleChange);
        }

        return () => {
            if (matchMedia.removeListener) {
                matchMedia.removeListener(handleChange);
            } else {
                matchMedia.removeEventListener('change', handleChange);
            }
        };
    }, [handleChange, query]);

    return matches;
};