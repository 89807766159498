import {Flex} from "@chakra-ui/react";
import TableUser from "./table";

const UserView = () => {
    return (
        <Flex flexDir={"column"}>
            <TableUser/>
        </Flex>
    )
}

export default UserView;