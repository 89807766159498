import {ErrorBoundary} from 'react-error-boundary';
import {Route, Routes} from 'react-router-dom';
import NotFound404 from "../page/404/404";
import ErrorFallback from "../page/layout/error-fallback";
import Main from "../page/layout/main";
import Home from "../page/home/home";
import Login from "../page/management/login";
import Management from "../page/management/management";
import CodeConfig from "../page/management/code_config/code-config";
import ValueChart from "../page/management/value_chart/value-chart";
import {useRecoilState, useSetRecoilState} from "recoil";
import {tokenState, userProfileState} from "../page/recoil";
import {Flex} from "@chakra-ui/react";
import {useCallback, useEffect} from "react";
import {serviceLp} from "../utils/service";
import Logout from "../page/logout";
import UserView from "../page/management/user/user-view";

const AppRoute = () => {
    const [token, setToken] = useRecoilState(tokenState);
    const setUserProfile = useSetRecoilState(userProfileState);

    const onLogout = useCallback(() => {
        setToken("");
        setUserProfile("");
        window.location.href = "/login";
    }, [setToken, setUserProfile]);

    useEffect(() => {
        if (token) {
            serviceLp.validate().then(res => {
                setUserProfile(JSON.stringify(res));
            }).catch(error => {
                console.log(error);
                onLogout();
            })
        }
    }, []);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {token ?
                <Routes>
                    <Route path="/management" element={<Management/>}>
                        <Route index element={<Flex/>}/>
                        <Route path="code-config" element={<CodeConfig/>}/>
                        <Route path="value-chart" element={<ValueChart/>}/>
                        <Route path="user" element={<UserView/>}/>
                        <Route path="*" element={<NotFound404/>}/>
                    </Route>
                    <Route element={<Main/>}>
                        <Route index element={<Home/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="/login" element={<Login token={token} setToken={setToken}/>}/>
                        <Route path="*" element={<NotFound404/>}/>
                    </Route>
                </Routes> :
                <Routes>
                    <Route element={<Main/>}>
                        <Route index element={<Home/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="/login" element={<Login setToken={setToken}/>}/>
                        <Route path="*" element={<NotFound404/>}/>
                    </Route>
                </Routes>
            }
        </ErrorBoundary>
    );
};

export default AppRoute;
