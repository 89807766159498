import React, {useEffect, useState} from 'react';
import {Button, DatePicker, message, Select, Space, Table} from 'antd';
import {serviceLp} from "../../../utils/service";
import {Flex} from "@chakra-ui/react";
import ModalValueChart from "./modal";
import {CREATE, DELETE, EDIT, VIEW} from "../../../utils/const";
import FileSaver from 'file-saver';
import {format} from "date-fns";
import UploadValueChart from "./upload";

const {RangePicker} = DatePicker;
const TableValueChart = () => {
    const [codeSearch, setCodeSearch] = useState([]);
    const [rangeDateSearch, setRangeDateSearch] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [pagination, setPagination] = useState({pageSize: 10, current: 1});
    const [data, setData] = useState([]);
    const [response, setResponse] = useState({});
    const [optionCodes, setOptionCodes] = useState([]);
    const [type, setType] = useState('');
    const [record, setRecord] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        serviceLp.getAllCode().then((res) => {
            setOptionCodes(res.map(data => ({
                value: data?.code,
                label: data?.code,
            })))
        }).catch((err) => {
            message.error(err?.description).then(r => console.log(r));
        });
    }, []);

    useEffect(() => {
        fetchData();
    }, [codeSearch, rangeDateSearch, pageIndex, pageSize]);

    const fetchData = () => {
        const fromDate = rangeDateSearch && rangeDateSearch.length > 0 && rangeDateSearch[0] ? format(new Date(rangeDateSearch[0]), 'yyyy-MM-dd') : ''
        const toDate = rangeDateSearch && rangeDateSearch.length > 0 && rangeDateSearch[1] ? format(new Date(rangeDateSearch[1]), 'yyyy-MM-dd') : ''
        serviceLp.searchDataInvestment({
            symbols: codeSearch.join(','),
            fromDate: fromDate,
            toDate: toDate,
            page: pageIndex,
            size: pageSize,
            sortBy: 'date:desc'
        }).then((res) => {
            setResponse(res);
            setData(res?.data?.map(data => ({
                id: data?.id,
                symbol: data?.symbol,
                close: data?.close,
                date: data?.date,
            })))
        }).catch((err) => {
            message.error(err?.description).then(r => console.log(r));
        });
    }

    const columns = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (_, record) => <a onClick={() => {
                setRecord(record);
                showModal(VIEW);
            }}>{record?.symbol}</a>,
        },
        {
            title: 'Close',
            dataIndex: 'close',
            key: 'close',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => {
                        setRecord(record);
                        showModal(EDIT);
                    }}>Chỉnh sửa</a>
                    <a onClick={() => {
                        setRecord(record);
                        showModal(DELETE);
                    }}>Xóa</a>
                </Space>
            ),
        },
    ];

    const showModal = (type) => {
        setIsModalOpen(true);
        setType(type);
    };

    const handleTableChange = (index, size) => {
        setPageIndex(index);
        setPageSize(size)
        setPagination({pageSize: size, current: index});
    };

    const downloadTemplateImport = () => {
        serviceLp.getTemplate().then((res) => {
            FileSaver(res, 'Template Import.xlsx');
        }).catch((err) => {
            console.log(err)
            message.error(err?.description).then(r => console.log(r));
        });
    }

    return (
        <Flex flexDir={"column"}>
            <ModalValueChart isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} type={type} record={record}
                             fetchData={fetchData}/>
            <Flex w={'100%'} mb={20} justifyContent={"center"} alignItems={"center"}>
                <Flex w={'100%'} justifyContent={"start"}>
                    <RangePicker onChange={(value) => setRangeDateSearch(value)}/>
                    <Select
                        mode="multiple"
                        style={{width: '30%', marginLeft: 10}}
                        options={optionCodes}
                        onChange={(value) => setCodeSearch(value)}
                    />
                </Flex>
                <Flex w={'100%'} justifyContent={"end"} alignItems={"center"}>
                    <Button
                        type="primary"
                        onClick={() => showModal(CREATE)}
                        style={{width: "fit-content"}}
                    >
                        Thêm mới
                    </Button>
                    <UploadValueChart fetchData={fetchData}/>
                    <Button
                        type="primary"
                        onClick={() => downloadTemplateImport()}
                        style={{marginLeft: 10, width: "fit-content"}}
                    >
                        Tải template import
                    </Button>
                </Flex>
            </Flex>
            <Table columns={columns}
                   pagination={{
                       total: response?.totalElements,
                       ...pagination,
                       onChange: handleTableChange, // Gọi hàm này khi người dùng thay đổi trang
                   }}
                   dataSource={data}/>
        </Flex>
    )
}
export default TableValueChart;