import React, {useState} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, message, Spin, Upload} from 'antd';
import {serviceLp} from "../../../utils/service";
import FileSaver from 'file-saver';
import PropTypes from "prop-types";

const UploadValueChart = ({fetchData}) => {
    const [loading, setLoading] = useState(false);

    const handleUpload = async ({file}) => {
        setLoading(true);
        serviceLp.importTemplate(file).then(response => {
            FileSaver(response, 'result.xlsx');
        }).catch(error => {
            console.log(error)
            message.error(`${file.name} file upload failed.`);
        }).finally(() => {
            fetchData();
            setLoading(false);
        })
    };

    const props = {
        customRequest: handleUpload,
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
        },
    };

    return (
        <Spin spinning={loading}>
            <Upload {...props} showUploadList={false}>
                <Button
                    icon={<UploadOutlined/>}
                    type="primary"
                    style={{marginLeft: 10, width: "fit-content"}}
                >
                    Import
                </Button>
            </Upload>
        </Spin>
    );
};

UploadValueChart.propTypes = {
    fetchData: PropTypes.func
}

export default UploadValueChart;
