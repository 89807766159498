import API from "./method";

const getBaseUrl = () => {
    if (window.location.hostname.includes('lpinvestment.net')) {
        return process.env.REACT_APP_LP_API_NET;
    } else if (window.location.hostname.includes('lpinvestment.vn')) {
        return process.env.REACT_APP_LP_API_VN;
    } else if (window.location.hostname.includes('lpinvestment.com.vn')) {
        return process.env.REACT_APP_LP_API_COM_VN;
    } else {
        return process.env.REACT_APP_LP_API; // Default case
    }
};

const serviceLp = {
    login: (params) => {
        return API.request({
            url: `/api/auth/login`,
            method: "POST",
            baseURL: getBaseUrl(),
            params: params,
        });
    },
    validate: () => {
        return API.request({
            url: `/api/user`,
            method: "GET",
            baseURL: getBaseUrl(),
        });
    },
    getAllUser: () => {
        return API.request({
            url: `/api/user/get-all`,
            method: "GET",
            baseURL: getBaseUrl(),
        });
    },
    getAllCode: () => {
        return API.request({
            url: `/api/symbol/get-all`,
            method: "GET",
            baseURL: getBaseUrl(),
        });
    },
    getByCode: (code) => {
        return API.request({
            url: `/api/symbol/${code}`,
            method: "GET",
            baseURL: getBaseUrl(),
        });
    },
    createCode: (params) => {
        return API.request({
            url: `/api/symbol`,
            method: "POST",
            baseURL: getBaseUrl(),
            params: params,
        });
    },
    updateCode: (id, params) => {
        return API.request({
            url: `/api/symbol/${id}`,
            method: "PUT",
            baseURL: getBaseUrl(),
            params: params,
        });
    },
    deleteCode: (id) => {
        return API.request({
            url: `/api/symbol/${id}`,
            method: "DELETE",
            baseURL: getBaseUrl(),
        });
    },
    searchDataInvestment: (params) => {
        return API.request({
            url: `/api/investment/search`,
            method: "GET",
            baseURL: getBaseUrl(),
            params: params
        });
    },
    getDataChart: (params) => {
        return API.request({
            url: `/api/investment/compare`,
            method: "GET",
            baseURL: getBaseUrl(),
            params: params
        });
    },
    createDataChart: (params) => {
        return API.request({
            url: `/api/stock-index`,
            method: "POST",
            baseURL: getBaseUrl(),
            params: params,
        });
    },
    updateDataChart: (id, params) => {
        return API.request({
            url: `/api/stock-index/${id}`,
            method: "PUT",
            baseURL: getBaseUrl(),
            params: params,
        });
    },
    deleteDataChart: (id) => {
        return API.request({
            url: `/api/stock-index/${id}`,
            method: "DELETE",
            baseURL: getBaseUrl(),
        });
    },
    getTemplate: () => {
        return API.request({
            url: `/api/stock-index/get-template`,
            method: "GET",
            baseURL: getBaseUrl(),
            responseType: 'blob'
        });
    },
    importTemplate: (file) => {
        return API.upload({
            file: file,
            url: `/api/stock-index/import`,
            baseURL: getBaseUrl(),
            responseType: 'blob',
        });
    },
    syncDataDStock: (from, to) => {
        const fromParams = from ? `&from=${from}` : "";
        const toParams = to ? `&to=${to}` : "";
        return API.request({
            url: `api/stock-index/sync/dstock?code=VNINDEX${fromParams}${toParams}`,
            method: "GET",
            baseURL: getBaseUrl()
        });
    },
    calculatePerformance: (params) => {
        return API.request({
            url: `/api/investment/calculate/performance`,
            method: "POST",
            baseURL: getBaseUrl(),
            params: params,
        });
    },
    changePassword: (params) => {
        return API.request({
            url: `/api/auth/change-password`,
            method: "POST",
            baseURL: getBaseUrl(),
            params: params,
        });
    },
};

export {serviceLp};