import {Flex} from "@chakra-ui/react";
import TableCodeConfig from "./table";

const CodeConfig = () => {
    return (
        <Flex flexDir={"column"}>
            <TableCodeConfig/>
        </Flex>
    )
}

export default CodeConfig;